<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 bg-start-img"
        :style="{ backgroundImage: `url('${imgUrl}')`, backgroundRepeat:'no-repeat', backgroundPosition: 'center' }"
      >

        <!-- Brand logo-->
        <div class="w-100 h-100 d-lg-flex px-1">
          <logo />
        </div>
        <!-- /Brand logo-->
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg p-2 p-lg-5"
      >
        <b-overlay
          :show="loading"
          variant="transparent"
          opacity="1"
          blur="5px"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
        >

          <b-col
            lg="12"
            class="px-xl-2 py-4 py-sm-0 mx-auto"
          >
            <b-card-title class="mb-1">
              {{ $t('Validate your Evorgy account') }} 🚀
            </b-card-title>
            <b-card-text class="mb-2">
              {{ email }}
              <br>
              https://panel.evorgy.app/{{ slug }}
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="validateForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="validate"
              >
                <!-- username -->
                <b-form-group
                  label="Nombre del responsable"
                  label-for="register-username"
                >
                  <template v-slot:label>
                    {{ $t('Name of the responsible') }} <code>*</code>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="register-username"
                      v-model="name"
                      name="register-username"
                      :state="errors.length > 0 ? false:null"
                      placeholder="johndoe"
                    />
                  </validation-provider>
                </b-form-group>

                <!-- company -->
                <b-form-group
                  :label="$t('Tenant.company_name')"
                  label-for="register-company"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Empresa"
                    vid="company"
                    rules="required"
                  >
                    <b-form-input
                      id="register-company"
                      v-model="company"
                      name="register-company"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Empresa"
                      disabled
                    />
                  </validation-provider>
                </b-form-group>

                <!-- legalId -->
                <b-form-group
                    :label="$t('legal id')"
                  label-for="register-legalId"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CIF"
                    vid="legalId"
                  >
                    <b-form-input
                      id="register-legalId"
                      v-model="legalId"
                      name="register-legalId"
                      placeholder="CIF"
                    />
                  </validation-provider>
                </b-form-group>

                <!-- address_1 -->
                <b-form-group
                    :label="$t('address1')"
                  label-for="register-address1"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Dirección"
                    vid="address1"
                  >
                    <b-form-input
                      id="register-address1"
                      v-model="address1"
                      name="register-address1"
                      placeholder="Dirección"
                    />
                  </validation-provider>
                </b-form-group>

                <!-- address_2 -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="direccion2"
                    vid="address2"
                  >
                    <b-form-input
                      id="register-address2"
                      v-model="address2"
                      name="register-address2"
                      placeholder="Dirección"
                    />
                  </validation-provider>
                </b-form-group>

                <!-- cp -->
                <b-form-group
                  label="Código Postal"
                  label-for="register-company"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Código Postal"
                    vid="postalCode"
                  >
                    <b-form-input
                      id="register-postalCode"
                      v-model="postalCode"
                      name="register-postalCode"
                      placeholder="Código Postal"
                    />
                  </validation-provider>
                </b-form-group>

                <!-- city -->
                <b-form-group
                  label="Ciudad"
                  label-for="register-city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Ciudad"
                    vid="city"
                  >
                    <b-form-input
                      id="register-city"
                      v-model="city"
                      name="register-city"
                      placeholder="Ciudad"
                    />
                  </validation-provider>
                </b-form-group>

                <!-- logo -->
                <b-form-group
                  label="Logo"
                  label-for="register-logo"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Logo"
                    vid="logo"
                  >
                    <b-form-file
                      id="register-logo"
                      v-model="logo"
                      :placeholder=" logo ? logo.name : $t('Company_logo.Choose a file') "
                      :drop-placeholder="$t('Drop file here')"
                      :browse-text="$t('Search')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- background -->
                <!--
                <b-form-group
                  label="Background"
                  label-for="register-background"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Background"
                    vid="background"
                  >
                    <b-form-file
                      id="register-background"
                      v-model="background"
                      :placeholder=" background ? background.name : 'Choose a file or drop it here...' "
                      drop-placeholder="Drop file here..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="status"
                    vid="status"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="status"
                      v-model="status"
                      name="status"
                      :state="errors.length > 0 ? false:null"
                    >
                      {{ $t('Acept') }}
                      <b-link>{{ $t('Privacy_policy_terms') }}</b-link>
                    </b-form-checkbox>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  {{ $t('Activate account') }}
                </b-button>
              </b-form>
            </validation-observer>

          </b-col>
        </b-overlay>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormFile, BFormInput, BImg, BCardTitle, BCardText, BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'
import store from '@/store'

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormFile,
    BFormInput,
    BOverlay,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      email: '',
      slug: '',
      name: '',
      company: '',
      legalId: '',
      address1: '',
      address2: '',
      postalCode: '',
      city: '',
      logo: null,
      background: null,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      loading: false,
      // validation
      required,
    }
  },
  computed: {
    uuid() {
        return this.$route.params.uuid
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    async getCompany() {
      await axios.get('/sanctum/csrf-cookie')
      await axios.get(`/api/v1/account-data/${this.uuid}`)
      .then(async res => {
        this.name = res.data.name
        this.email = res.data.email
        this.company = res.data.company
        this.slug = res.data.slug
        this.legalId = res.data.legal_id
        this.address1 = res.data.address_1
        this.address2 = res.data.address_2
        this.postalCode = res.data.postal_code
        this.city = res.data.city
      })
      .catch(error => {
        this.$refs.validateForm.setErrors(error.response.data.error)
      })
    },
    async validate() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.loading = true
          const formHeaders = { headers: { 'Content-Type': 'multipart/form-data' } }
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('slug', this.slug)
          if (!_.isNull(this.legalId)) formData.append('legal_id', this.legalId)
          if (!_.isNull(this.address1)) formData.append('address_1', this.address1)
          if (!_.isNull(this.address2)) formData.append('address_2', this.address2)
          if (!_.isNull(this.postalCode)) formData.append('postal_code', this.postalCode)
          if (!_.isNull(this.city)) formData.append('city', this.city)
          if (!_.isNull(this.logo)) formData.append('logo', this.logo)
          if (!_.isNull(this.background)) formData.append('start_image', this.background)

          await axios.post(`/api/v1/tenants/${this.uuid}/confirm`, formData, formHeaders)
          .then(async res => {
            localStorage.setItem('currentSlug', res.data)

            this.$router.replace({ name: 'auth-login', params: { slug: res.data } })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Cuenta activada`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Puede loguearse con su usuario para disfrutar de los servicios Evorgy!`,
                },
              })
            })
          })
          .catch(error => {
            this.$refs.validateForm.setErrors(error.response.data.error)
          })
          this.loading = false
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
