var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5 bg-start-img",style:({ backgroundImage: ("url('" + _vm.imgUrl + "')"), backgroundRepeat:'no-repeat', backgroundPosition: 'center' }),attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 h-100 d-lg-flex px-1"},[_c('logo')],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg p-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":"transparent","opacity":"1","blur":"5px","spinner-variant":"primary","spinner-type":"grow","spinner-small":""}},[_c('b-col',{staticClass:"px-xl-2 py-4 py-sm-0 mx-auto",attrs:{"lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Validate your Evorgy account'))+" 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.email)+" "),_c('br'),_vm._v(" https://panel.evorgy.app/"+_vm._s(_vm.slug)+" ")]),_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Nombre del responsable","label-for":"register-username"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Name of the responsible'))+" "),_c('code',[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"johndoe"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Tenant.company_name'),"label-for":"register-company"}},[_c('validation-provider',{attrs:{"name":"Empresa","vid":"company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-company","name":"register-company","state":errors.length > 0 ? false:null,"placeholder":"Empresa","disabled":""},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('legal id'),"label-for":"register-legalId"}},[_c('validation-provider',{attrs:{"name":"CIF","vid":"legalId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-legalId","name":"register-legalId","placeholder":"CIF"},model:{value:(_vm.legalId),callback:function ($$v) {_vm.legalId=$$v},expression:"legalId"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('address1'),"label-for":"register-address1"}},[_c('validation-provider',{attrs:{"name":"Dirección","vid":"address1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address1","name":"register-address1","placeholder":"Dirección"},model:{value:(_vm.address1),callback:function ($$v) {_vm.address1=$$v},expression:"address1"}})]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"direccion2","vid":"address2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address2","name":"register-address2","placeholder":"Dirección"},model:{value:(_vm.address2),callback:function ($$v) {_vm.address2=$$v},expression:"address2"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Código Postal","label-for":"register-company"}},[_c('validation-provider',{attrs:{"name":"Código Postal","vid":"postalCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-postalCode","name":"register-postalCode","placeholder":"Código Postal"},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Ciudad","label-for":"register-city"}},[_c('validation-provider',{attrs:{"name":"Ciudad","vid":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-city","name":"register-city","placeholder":"Ciudad"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Logo","label-for":"register-logo"}},[_c('validation-provider',{attrs:{"name":"Logo","vid":"logo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"register-logo","placeholder":_vm.logo ? _vm.logo.name : _vm.$t('Company_logo.Choose a file'),"drop-placeholder":_vm.$t('Drop file here'),"browse-text":_vm.$t('Search')},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"status","name":"status","state":errors.length > 0 ? false:null},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t('Acept'))+" "),_c('b-link',[_vm._v(_vm._s(_vm.$t('Privacy_policy_terms')))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('Activate account'))+" ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }